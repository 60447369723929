'use client';

import { Container, Icon, IconName, IconSize, Text, TextLevels, TypographyBold } from '@bytel/trilogy-react';

export function HandsFree() {
    return (
        <Container data-cy='hands-free'>
            <Text level={TextLevels.ONE} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                <Icon size={IconSize.MEDIUM} name={IconName.HANDSFREE} />
                Kit mains libres recommandé
            </Text>
        </Container>
    );
}
