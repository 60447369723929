import { isAfter, isBefore, parseISO } from 'date-fns';

export const isValidDateRange = (start_date?: string, end_date?: string): boolean => {
    const now = new Date();
    const startDate = start_date ? parseISO(start_date) : null;
    const endDate = end_date ? parseISO(end_date) : null;
    return (!startDate || isAfter(now, startDate)) && (!endDate || isBefore(now, endDate));
};

export const isValidParcours = (journey: string, parcours: string[]): boolean => {
    return parcours.includes(journey);
};

export const filterValidItems = (
    journey: string,
    parcours: string[],
    start_date?: string,
    end_date?: string,
): boolean => {
    return isValidParcours(journey, parcours) && isValidDateRange(start_date, end_date);
};
