'use client';

import { useEffect, useState } from 'react';

import { ActiveFiltersType } from '@bytel/product-wall';
import { countActiveFilters } from '@bytel/product-wall/helpers';
import { Divider } from '@bytel/trilogy-react';

import { WallConfigType } from '@app-types/config';

import { Breadcrumb as BreadcrumbComponent, BreadcrumbElement } from '@components/breadcrumb';

import { aggregateFilters } from '@helpers/seo';

type BreadcrumbProps = {
    wallConfig: WallConfigType;
    activeFilters: ActiveFiltersType;
};

export function Breadcrumb({ wallConfig, activeFilters }: BreadcrumbProps) {
    const [elements, setElements] = useState<BreadcrumbElement[]>([]);

    useEffect(() => {
        const defaultElements: BreadcrumbElement[] = [
            {
                name: 'home',
                label: 'Accueil',
                href: '/',
            },
            {
                name: 'wall',
                label: `${wallConfig.breadcrumb.baseText}${wallConfig.breadcrumb.title}`,
                href: wallConfig.baseUrl,
            },
        ];
        const newElements: BreadcrumbElement[] = [];

        const activeFiltersCount = countActiveFilters(activeFilters);

        if (
            0 < activeFiltersCount &&
            activeFiltersCount <= 2 &&
            !Object.values(activeFilters).some((values) => values.length > 1)
        ) {
            newElements.push({
                name: 'filter',
                label: `${wallConfig.breadcrumb.baseText} ${aggregateFilters(activeFilters, wallConfig, 'title').join(
                    ' ',
                )}`,
            });
        }
        setElements([...defaultElements, ...newElements]);
    }, [activeFilters]);

    return (
        <>
            <BreadcrumbComponent elements={elements} />
            <Divider />
        </>
    );
}
