import { getWallConfig } from '@services/config';
import { wallService } from '@services/products/wall';

import { WallType } from '@app-types/config';
import { SearchParamsType } from '@app-types/page';

import { AccessoryImageThumbor } from '@components/image-thumbor';

import { getActiveFilters } from '@helpers/seo';

import { Wall } from '@product-wall/components';

const wallConfig = getWallConfig(WallType.ACCESSORY);

interface AccessoryWallProps {
    params?: { filter: string };
    searchParams?: SearchParamsType;
}

export async function AccessoryWall({ searchParams, params }: Readonly<AccessoryWallProps>) {
    const page = typeof searchParams?.page === 'string' ? Number(searchParams.page) : 1;
    const sort = typeof searchParams?.sort === 'string' ? searchParams.sort : 'meilleures-ventes';
    const search = searchParams?.nom;
    const activeFilters = getActiveFilters(wallConfig, params?.filter ?? '', new URLSearchParams(searchParams));

    const productsPromise = wallService.getProduct(
        WallType.ACCESSORY,
        {
            sort,
            page,
            limit: wallConfig.productByPage,
            filters: activeFilters,
            search,
        },
        true,
    );
    const searchListPromise = wallService.getSearchList(WallType.ACCESSORY);

    const [{ products, count }, searchList] = await Promise.all([productsPromise, searchListPromise]);

    return (
        <Wall
            title={wallConfig.baseTitle}
            type={WallType.ACCESSORY}
            products={products}
            pageSize={wallConfig.productByPage}
            count={count}
            startPage={page}
            activeSort={sort}
            wallConfig={wallConfig}
            searchParams={searchParams}
            activeFilters={activeFilters}
            searchList={searchList}
            search={search}
            handleLogin={false}
            imageAs={AccessoryImageThumbor}
            trackingData={{
                page: 'Mur_accessoires',
                pageCategory: 'Liste_produits_telephone',
                marketLine: 'Mobile',
                isB2B: false,
                eCommerceStep: 'Liste_produits_accessoires',
                variant: { Recommandation: products.some((product) => product.recommended) },
            }}
        />
    );
}
