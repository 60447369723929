'use client';

import Image from 'next/image';

import { Box, BoxContent, Link, Text, Title } from '@bytel/trilogy-react';

import noProductImage from '@assets/no-product.png';

type EmptyProductListProps = {
    resetFilters?: () => void;
};

export function EmptyProductList({ resetFilters }: EmptyProductListProps) {
    return (
        <div className='p-small'>
            <Box>
                <BoxContent className='has-text-centered'>
                    <Image src={noProductImage} alt='' fetchPriority='high' width='294' height='176' />
                    <Title className='center-title is-size-5' marginless>
                        Oh, il semblerait qu&apos;aucun résultat ne correspond à votre recherche.
                    </Title>
                    <Text>
                        Élargissez votre recherche en retirant des filtres ou{' '}
                        <Link onClick={() => resetFilters?.()}>en les effaçant tous</Link>. Vous trouverez forcément
                        votre bonheur !
                    </Text>
                </BoxContent>
            </Box>
        </div>
    );
}
