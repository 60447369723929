'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import {
    Column,
    Columns,
    Icon,
    IconName,
    IconSize,
    Modal,
    ModalBody,
    Text,
    TextLevels,
    TypographyBold,
} from '@bytel/trilogy-react';

export interface RedirectionOptionsType {
    acquisition: { url: string; icon?: IconName; cta?: string };
    renewal: { url?: string; icon?: IconName; cta?: string };
}

interface ProductRedirectModalProps {
    options: RedirectionOptionsType;
    onAction?: (shouldRedirect?: boolean) => void;
}

export function ProductRedirectModal({ options, onAction }: Readonly<ProductRedirectModalProps>) {
    const router = useRouter();

    const onExit = () => {
        onAction?.();
        router.push(options.acquisition.url);
    };

    return (
        <Modal active onClose={onExit} className='z-level-10' title={'Êtes-vous nouveau ou déjà client\u00A0?'}>
            <ModalBody>
                <Columns marginless>
                    <Column className='has-text-centered p-small'>
                        <Icon name={options.acquisition.icon ?? IconName.MOBILE} size={IconSize.LARGE} />
                        <Text level={TextLevels.ONE} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                            Nouveau client
                        </Text>
                        <Text level={TextLevels.TWO}>&nbsp;</Text>
                        <Link
                            className='button is-conversion'
                            onClick={() => onAction?.()}
                            href={options.acquisition.url}
                            data-cy='prospect-client'
                        >
                            {options.acquisition.cta ?? 'Continuer ma visite'}
                        </Link>
                    </Column>
                    <Column className='has-background-grey-fade rounded-lg has-text-centered p-small'>
                        <Icon name={options.renewal.icon ?? IconName.MOBILE_UP_DOWN} size={IconSize.LARGE} />
                        <Text level={TextLevels.ONE} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                            Déjà client&nbsp;?
                        </Text>
                        <Text level={TextLevels.TWO}>Profitez de prix personnalisés&nbsp;!</Text>
                        <Link
                            className='button is-primary'
                            onClick={() => onAction?.(true)}
                            href={options.renewal.url ?? ''}
                            data-cy='renewal-client'
                        >
                            {options.renewal.cta ?? 'Changer de téléphone'}
                        </Link>
                    </Column>
                </Columns>
            </ModalBody>
        </Modal>
    );
}
