import { ActiveFiltersType, StickersType } from '@bytel/product-wall';
import { countActiveFilters } from '@bytel/product-wall/helpers';

import { cmsService } from '@services/cms';
import { getWallConfig } from '@services/config';
import { httpService } from '@services/http';
import { HttpService } from '@services/http/http';

import { WallConfigType, WallType } from '@app-types/config';

import { isServer } from '@helpers/config';
import { localApiPaths } from '@helpers/path';

class StickersService {
    private httpService: HttpService;
    private config: WallConfigType;

    public constructor(httpService: HttpService) {
        this.httpService = httpService;
        this.config = getWallConfig(WallType.PHONE);
    }

    public async get(activeFilters: ActiveFiltersType, isRenewal: boolean = false): Promise<StickersType> {
        if (isServer()) {
            return this.getStickersServer(activeFilters, isRenewal);
        }

        return this.httpService
            .get<StickersType>(localApiPaths.stickers, { activeFilters: JSON.stringify(activeFilters), isRenewal })
            .catch(() => {
                return { products: [], count: 0 };
            });
    }

    private async getStickersServer(activeFilters: ActiveFiltersType, isRenewal: boolean): Promise<StickersType> {
        const genericSliderUrl: string = isRenewal ? 'stickers-mdt-rnw' : 'stickers-mdt-acq';
        let url = genericSliderUrl;

        if (countActiveFilters(activeFilters) === 1) {
            const [key, value] = Object.entries(activeFilters)[0] as [string, string[]];
            const urlKey = Object.values(this.config.filtersObject[key]?.values ?? {}).find(
                (v) => v.value === value[0],
            )?.key;
            url = urlKey ? `${genericSliderUrl}-${urlKey}` : genericSliderUrl;
        }

        try {
            return await this.getStickersList(url);
        } catch (e) {
            if (url === genericSliderUrl) {
                return {};
            }

            return this.getStickersList(genericSliderUrl)
                .then((stickers) => stickers)
                .catch(() => ({}));
        }
    }

    private async getStickersList(url: string) {
        return cmsService.getStickers(url);
    }
}

export const stickersService = new StickersService(httpService);
