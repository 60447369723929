import { Session } from 'next-auth';

import { getAccordionSeo } from '@services/cms/accordion-seo';
import { getSlider } from '@services/cms/sliders';
import { getWallConfig } from '@services/config';
import { getCurrentPlan } from '@services/products';
import { stickersService } from '@services/products/stickers';
import { wallService } from '@services/products/wall';

import { WallType } from '@app-types/config';
import { ContractType } from '@app-types/contract';
import { SearchParamsType } from '@app-types/page';
import { MobilePlanProvenanceType } from '@app-types/plan';
import { UserType } from '@app-types/user';

import { ImageThumbor } from '@components/image-thumbor';

import { generateSubtitle, getActiveFilters } from '@helpers/seo';

import { Wall } from './wall';

const wallConfig = getWallConfig(WallType.PHONE);

interface PhoneWallProps {
    isRenewal?: boolean;
    contract?: ContractType;
    contractId?: string;
    searchParams?: SearchParamsType;
    params?: { filter: string };
    session?: Session | null;
    handleLogin?: boolean;
}

export async function PhoneWall({
    isRenewal = false,
    contract,
    contractId,
    searchParams,
    params,
    session,
    handleLogin = true,
}: Readonly<PhoneWallProps>) {
    const page = typeof searchParams?.page === 'string' ? Number(searchParams.page) : 1;
    const sort = typeof searchParams?.sort === 'string' ? searchParams.sort : 'meilleures-ventes';
    const activeFilters = getActiveFilters(wallConfig, params?.filter ?? '', new URLSearchParams(searchParams));
    const search = searchParams?.nom;

    const planPromise = getCurrentPlan(new URLSearchParams(searchParams), undefined, isRenewal, contract);
    const stickersPromise = stickersService.get(activeFilters, isRenewal);

    const [plan, stickers] = await Promise.all([planPromise, stickersPromise]);

    const productsPromise =
        contractId || !isRenewal
            ? wallService.getProduct(
                  WallType.PHONE,
                  {
                      sort,
                      page,
                      limit: wallConfig.productByPage,
                      filters: activeFilters,
                      plan: plan.gencode,
                      search,
                      contract: contractId,
                      stickers: Object.keys(stickers),
                  },
                  session === null,
              )
            : Promise.resolve({ products: [], count: 0 });
    const sliderPromise = getSlider(wallConfig, activeFilters, isRenewal);
    const accordionSeoPromise = getAccordionSeo(wallConfig, activeFilters, isRenewal);
    const searchListPromise = wallService.getSearchList(WallType.PHONE);

    const [{ products, count }, slider, accordionSeo, searchList] = await Promise.all([
        productsPromise,
        sliderPromise,
        accordionSeoPromise,
        searchListPromise,
    ]);

    const subtitle = plan.origin !== MobilePlanProvenanceType.DEFAULT ? generateSubtitle(plan) : undefined;

    return (
        <Wall
            title={wallConfig.baseTitle}
            type={WallType.PHONE}
            products={products}
            pageSize={wallConfig.productByPage}
            count={count}
            wallConfig={wallConfig}
            startPage={page}
            activeFilters={activeFilters}
            activeSort={sort}
            searchParams={searchParams}
            slider={slider}
            accordionSeo={accordionSeo}
            stickers={stickers}
            plan={plan}
            subtitle={subtitle}
            enableKonami
            enableHandsFree
            enableCompare
            searchList={searchList}
            search={search}
            isRenewal={isRenewal}
            user={session?.user as UserType | undefined}
            contract={contract}
            isLoading={isRenewal && !contractId}
            handleLogin={handleLogin}
            imageAs={ImageThumbor}
            trackingData={{
                page: 'Mur_de_telephone',
                pageCategory: 'Liste_produits_telephone',
                marketLine: 'Mobile',
                isB2B: false,
                eCommerceStep: 'Liste_produits',
                variant: { Recommandation: products.some((product) => product.recommended) },
            }}
        />
    );
}
