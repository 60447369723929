import { Session } from 'next-auth';

import { PriceDetailPosition } from '@bytel/product-wall/types';

import { cmsService } from '@services/cms';
import { httpService } from '@services/http';
import { HttpConfig, HttpService } from '@services/http/http';
import { getDefaultPlan } from '@services/products/plan';
import { reviewsServices } from '@services/reviews';

import { getServerSession } from '@actions/get-session';

import { SapicGetOptionsType, sapicRepository } from '@repositories/sapic';

import { PalpatineProductHighlights, PalpatineProductStickers } from '@app-types/api/palpatine';
import { SapicCategoryId, SapicFundingMode, SapicProductPayloadV4 } from '@app-types/api/sapic';
import { QUOTE_TYPES } from '@app-types/cart';
import { WallType } from '@app-types/config';
import { DiscountEnumType } from '@app-types/discount';
import { Product } from '@app-types/product';
import { ReviewAverageType } from '@app-types/review';
import { SimulatorCart } from '@app-types/simulator/virtual-cart';
import { UserType } from '@app-types/user';

import { isServer } from '@helpers/config';
import { filterValidItems } from '@helpers/content-editor';
import { isComptant } from '@helpers/funding';
import { localApiPaths } from '@helpers/path';

import { API_X_VERSION } from '@constants/api';
import { JourneyType } from '@constants/journey';

import { getPagination } from '@product-wall/helpers';
import { Item } from '@trilogy-ds/react/lib/components/autocomplete';
import { VariantState } from '@trilogy-ds/react/lib/objects/facets/Variant';

interface GetProductOptionType {
    plan?: string;
    filters?: Record<string, string[]>;
    search?: string;
    contract?: string;
    stickers?: string[];
    sort?: string;
    page?: number;
    limit?: number;
}

type MarketingData = {
    highlight?: PalpatineProductHighlights;
    stickers?: PalpatineProductStickers[];
    partnerId?: string;
};

class WallService {
    private httpService: HttpService;

    public constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getProduct(
        type: WallType,
        options: GetProductOptionType,
        shouldBeProspect: boolean = false,
        _session?: Session,
    ): Promise<{ products: Product[]; count: number }> {
        const session = shouldBeProspect ? null : (_session ?? (await getServerSession()));
        if (isServer()) {
            return this.getProductServer(type, options, session);
        }

        const config: HttpConfig = {};

        if (session?.user) {
            config.user = session.user as UserType;
        }

        return this.httpService
            .get<{ products: Product[]; count: number }>(
                localApiPaths.wall,
                {
                    type,
                    options: JSON.stringify(options),
                    shouldBeProspect,
                },
                config,
            )
            .catch(() => {
                return { products: [], count: 0 };
            });
    }

    public async getSearchList(type: WallType): Promise<Item[]> {
        if (isServer()) {
            return this.getSearchListServer(type);
        }

        return this.httpService
            .get<Item[]>(localApiPaths.wallSearchList, {
                type,
            })
            .catch(() => {
                return [];
            });
    }

    private async getProductServer(
        type: WallType,
        options: GetProductOptionType,
        session: Session | null,
    ): Promise<{ products: Product[]; count: number }> {
        const virtualCart = this.buildVirtualCart(options.contract, options.plan);

        const sapicOptions = this.buildSapicOptions(type, options);
        const [marketingDataResult, sapicResult] = await Promise.allSettled([
            this.getCmsInfo(type, options.contract !== undefined),
            sapicRepository.getProducts(virtualCart, sapicOptions, session, API_X_VERSION.V4),
        ]);

        if (sapicResult.status === 'rejected') {
            return { products: [], count: 0 };
        }

        const marketingData = marketingDataResult.status === 'fulfilled' ? marketingDataResult.value : {};

        const { products, count } = sapicResult.value;

        const reviews = await this.getReviews(products);

        return {
            products: products.map((product) =>
                this.wallProductMapper(type, product, marketingData[product.urlKey], reviews[product.gencode]),
            ),
            count,
        };
    }

    private async getSearchListServer(type: WallType): Promise<Item[]> {
        const virtualCart = this.buildVirtualCart();
        const options = this.buildSapicOptions(type, { limit: -1 });

        const productsResult = await sapicRepository.getProducts(virtualCart, options);

        return productsResult.products
            .map((product) => {
                return {
                    data: `${product.brand} ${product.name}`,
                    label: `${product.brand} ${product.name}`,
                };
            })
            .sort((a, b) => a.data.localeCompare(b.data));
    }

    private buildVirtualCart(contractId?: string, mobilePlan?: string): SimulatorCart {
        const isRenewal = contractId !== undefined;

        if (!mobilePlan) {
            mobilePlan = getDefaultPlan(isRenewal).toString();
        }

        return {
            cart: {
                quotes: [
                    {
                        type: isRenewal ? QUOTE_TYPES.RENEWAL : QUOTE_TYPES.ACQUISITION,
                        isCurrent: true,
                        products: mobilePlan ? [{ gencode: mobilePlan }] : [],
                        contractId,
                    },
                ],
            },
        };
    }

    private buildSapicOptions(type: WallType, options: GetProductOptionType): SapicGetOptionsType {
        let paginationData;

        if (options.page && options.page > 0 && options.limit) {
            const stickersPosition =
                options.stickers?.map((sticker) => {
                    return parseInt(sticker);
                }) ?? [];

            paginationData = getPagination(options.page, options.limit, stickersPosition);
        }

        const limit = paginationData?.limit ?? options.limit;
        const offset = paginationData?.offset ?? 0;
        const sort = options.sort ?? 'meilleures-ventes';

        const category: SapicCategoryId = type === WallType.PHONE ? SapicCategoryId.PHONE : SapicCategoryId.ACCESSORY;

        const searchParams: Record<string, string> = {};

        if (options.search) {
            searchParams.nom = options.search;
        }

        if (options.filters) {
            Object.entries(options.filters).forEach((filtersData) => {
                const key = filtersData[0] as keyof SapicProductPayloadV4;
                if (key === 'prix') {
                    searchParams[key] = this.getPriceRange(filtersData[1]).join('-');
                    return;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                searchParams[key] = filtersData[1].join(',');
            });
        }

        return {
            category,
            searchParams,
            fundingMode: SapicFundingMode.AUTO,
            limit,
            offset,
            sort,
            contract: options.contract,
        };
    }

    private async getReviews(products: Product[]): Promise<Record<string, ReviewAverageType>> {
        const gencodes = products.map((product) => {
            return product.gencode;
        });

        if (gencodes.length > 0) {
            const reviews = await reviewsServices.getAverageReviews(gencodes);

            return (reviews ?? []).reduce((acc: Record<string, ReviewAverageType>, review) => {
                acc[review.gencode] = review;
                return acc;
            }, {});
        }

        return {};
    }

    private async getCmsInfo(type: WallType, isRenewal: boolean): Promise<Record<string, MarketingData>> {
        try {
            const { items } = await (type === WallType.PHONE
                ? cmsService.getProduct()
                : Promise.resolve({ items: [] }));

            const marketingData: Record<string, MarketingData> = {};

            items.forEach(({ Slug, highlight = { items: [] }, stickers = { items: [] }, id_les_numeriques }) => {
                const journeyType = isRenewal ? JourneyType.RENEWAL : JourneyType.ACQUISITION;
                const hightlightsValue = highlight.items.filter((datum) =>
                    filterValidItems(journeyType, datum.parcours, datum.start_date, datum.end_date),
                );
                const stickersValues = stickers.items.filter((datum) =>
                    filterValidItems(journeyType, datum.parcours, datum.start_date, datum.end_date),
                );

                marketingData[Slug] = {
                    partnerId: id_les_numeriques ?? undefined,
                    highlight: hightlightsValue[0],
                    stickers: stickersValues,
                };
            });

            return marketingData;
        } catch {
            return {};
        }
    }

    private wallProductMapper(
        type: WallType,
        product: Product,
        marketingData: MarketingData = {},
        review?: ReviewAverageType,
    ): Product {
        let stickers: Product['stickers'];

        const { highlight, stickers: _stickers, partnerId } = marketingData;

        if (type === WallType.PHONE) {
            stickers =
                _stickers?.map((sticker) => ({
                    label: sticker.sticker_text,
                    color: sticker.sticker_color as VariantState,
                })) ?? [];
        } else {
            stickers = product.stickers;
        }

        const funding = product.details.fundings[0];

        const isFunding = funding !== undefined && !isComptant(funding.type);
        let instalment: Product['instalment'] | undefined;

        if (isFunding) {
            instalment = {
                amount: funding.monthlyAmount,
                count: funding.installmentCount,
            };
        }

        const initialDeposit = funding?.initialDeposit ?? 0;
        const hasCashbackOffer =
            product.details.discounts.some(({ type }) => type === DiscountEnumType.ODR) && initialDeposit > 1;
        const instantDiscount =
            (product.details.price.subsidized || product.details.price.initial) - product.details.price.final;

        return {
            ...product,
            instalment,
            priceFrom: isFunding,
            priceDetail: this.getPriceDetail(isFunding, instantDiscount, hasCashbackOffer),
            rating: review
                ? {
                      value: review.rate,
                      count: review.count,
                  }
                : undefined,
            stickers,
            stamp: highlight?.stamp?.public_url
                ? {
                      url: highlight.stamp.public_url,
                      alt: highlight.stamp_alt ?? '',
                  }
                : undefined,
            partnerId,
        };
    }

    private getPriceDetail(
        isFunding: boolean,
        instantDiscount: number,
        hasCashbackOffer: boolean,
    ): { position: PriceDetailPosition; text?: string } | undefined {
        if (hasCashbackOffer) {
            return {
                position: PriceDetailPosition.BELOW,
                text: 'après remboursement',
            };
        }

        if (instantDiscount > 0) {
            return {
                position: PriceDetailPosition.BELOW,
                text: 'après remise',
            };
        }

        if (isFunding) {
            return {
                position: PriceDetailPosition.BESIDE,
            };
        }
    }

    private getPriceRange(priceRangeList: string[]): [number, number | undefined] {
        const defaultMin = Infinity;
        const defaultMax = -Infinity;

        const minMax = priceRangeList.reduce(
            ([min, max]: [number, number], currentValue): [number, number] => {
                let [currentMin, currentMax] = currentValue.split('-').map((value) => parseInt(value)) as [
                    number,
                    number,
                ];

                currentMin = isNaN(currentMin) ? -Infinity : currentMin;
                currentMax = isNaN(currentMax) ? Infinity : currentMax;

                min = min < currentMin ? min : currentMin;
                max = max > currentMax ? max : currentMax || Infinity;

                return [min, max];
            },
            [defaultMin, defaultMax],
        );

        const min = minMax[0] === -Infinity ? 0 : minMax[0];
        const max = minMax[1] === Infinity ? undefined : minMax[1];

        return [min, max];
    }
}

export const wallService = new WallService(httpService);
